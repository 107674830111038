.sidemenu {
    text-align: center;
    height: 100%;
    width: 10%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    border-radius: 0em;
    border: 0px;
    color: white;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.sidemenu::-webkit-scrollbar {
    display: none;
}

.sidemenu-button {
    width: 90%;
    height: 50px;
    border: none;
    outline: none;
    color: #fff;
    background: #111;
    z-index: 0;
    border-radius: 10px;
    font-family: Saira;
    cursor: crosshair;
    transition: 0.3s;
}

.sidemenu-button:hover {
    background: #fff;
    color: #111;
}
