@import url('https://fonts.googleapis.com/css?family=Saira&display=swap');

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  margin: 0;
  cursor: crosshair;
}

.bg {
  animation: slide 3s ease-in-out infinite alternate;
  background-image: linear-gradient(-60deg, rgb(50, 50, 50) 50%, rgb(125, 125, 125) 50%);
  bottom: 0;
  left: -50%;
  opacity: .5;
  position: fixed;
  right: -50%;
  top: 0;
  z-index: -1;
}

.bg2 {
  animation-direction: alternate-reverse;
  animation-duration: 4s;
}

.bg3 {
  animation-duration: 5s;
}

.content {
  backdrop-filter: blur(50px);
  background-color: rgba(0, 0, 0, .6);
  border-radius: 1.5em;
  box-shadow: 0 0 .25em rgba(0, 0, 0, .25);
  box-sizing: border-box;
  min-width: 60%;
  left: 55%;
  padding: 10vmin;
  position: fixed;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  font-family: Saira;
  font-size: 24px;
  color: white;
}

a {
  color: #ffffff;
  text-decoration: none;
}

h1 {
  font-size: 3vw;
  margin: 0.5vw;
}

h2 {
  font-size: 1.5vw;
  margin: 0.5vw;
}

h3 {
  font-size: 1vw;
  margin: 0.5vw;
}

@keyframes slide {
  0% {
    transform: translateX(-25%);
  }

  100% {
    transform: translateX(25%);
  }
}

p {
  margin: 10px;
}

input {
  margin: 0;
  text-align: center;
  width: 150px;
  height: 50px;
  border: none;
  outline: none;
  color: #fff;
  background: #111;
  z-index: 0;
  border-radius: 10px;
  font-family: Saira;
  cursor: crosshair;
  transition: 0.3s;
}
